import React, { useState } from 'react';
import { Star, X, AlertCircle, CheckCircle } from 'lucide-react';

interface ReportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  errors: string[];
}

const SuccessModal: React.FC<SuccessModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex items-start justify-between mb-4">
          <div className="flex items-center gap-2">
            <CheckCircle className="w-6 h-6 text-green-500" />
            <h2 className="text-xl font-bold">Report Submitted</h2>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <p className="text-gray-600 mb-4">Your report has been successfully submitted.</p>
        <p className="text-gray-800 font-medium">If No Broker Response Is Received Within 24 Hours Your Review Will Be Posted</p>
      </div>
    </div>
  );
};

const ErrorModal: React.FC<ErrorModalProps> = ({ isOpen, onClose, errors }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex items-start justify-between mb-4">
          <div className="flex items-center gap-2">
            <AlertCircle className="w-6 h-6 text-red-500" />
            <h2 className="text-xl font-bold">Error</h2>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <p className="text-gray-600 mb-4">Please fix the following issues:</p>
        <ul className="list-disc list-inside text-red-600">
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const ReportModal: React.FC<ReportModalProps> = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    loadNumber: '',
    daysToPayment: '',
    detentionPaid: 'yes',
    cancellationIssue: 'no',
    humanError: 'no',
    rating: 0,
    comments: ''
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  if (!isOpen) return null;

  const validateForm = () => {
    const newErrors: string[] = [];

    if (!formData.loadNumber.trim()) {
      newErrors.push('Load number is required');
    }
    if (!formData.daysToPayment.trim()) {
      newErrors.push('Days to payment is required');
    } else if (isNaN(Number(formData.daysToPayment)) || Number(formData.daysToPayment) < 0) {
      newErrors.push('Days to payment must be a valid number');
    }
    if (formData.rating === 0) {
      newErrors.push('Please provide a rating');
    }

    return newErrors;
  };

  const handleSubmit = () => {
    const validationErrors = validateForm();
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      setShowError(true);
      return;
    }

    // Here you would typically make an API call to submit the report
    console.log('Submitting report:', formData);
    
    onClose();
    setShowSuccess(true);
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40">
        <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
          <div className="flex items-start justify-between mb-6">
            <h2 className="text-2xl font-bold">Submit Report</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X size={24} />
            </button>
          </div>

          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Load Number
              </label>
              <input
                type="text"
                className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                value={formData.loadNumber}
                onChange={(e) => setFormData({ ...formData, loadNumber: e.target.value })}
                placeholder="Enter load number"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Days to Payment
              </label>
              <input
                type="number"
                className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                value={formData.daysToPayment}
                onChange={(e) => setFormData({ ...formData, daysToPayment: e.target.value })}
                placeholder="Enter days to payment"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Detention Paid
              </label>
              <select
                className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                value={formData.detentionPaid}
                onChange={(e) => setFormData({ ...formData, detentionPaid: e.target.value })}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Cancellation Issue
              </label>
              <select
                className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                value={formData.cancellationIssue}
                onChange={(e) => setFormData({ ...formData, cancellationIssue: e.target.value })}
              >
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Human Error
              </label>
              <select
                className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                value={formData.humanError}
                onChange={(e) => setFormData({ ...formData, humanError: e.target.value })}
              >
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Rating
              </label>
              <div className="flex gap-2">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    onClick={() => setFormData({ ...formData, rating: star })}
                    className="focus:outline-none"
                  >
                    <Star
                      className={`w-8 h-8 ${
                        star <= formData.rating ? 'fill-yellow-400 text-yellow-400' : 'text-gray-300'
                      }`}
                    />
                  </button>
                ))}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Additional Comments
              </label>
              <textarea
                className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                rows={4}
                value={formData.comments}
                onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
                placeholder="Enter any additional comments"
              />
            </div>

            <button
              onClick={handleSubmit}
              className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition-colors"
            >
              Submit Report
            </button>
          </div>
        </div>
      </div>

      <SuccessModal 
        isOpen={showSuccess} 
        onClose={() => setShowSuccess(false)} 
      />

      <ErrorModal 
        isOpen={showError} 
        onClose={() => setShowError(false)}
        errors={errors}
      />
    </>
  );
};

export default ReportModal;