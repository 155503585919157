import React, { useState } from 'react';
import { Flag } from 'lucide-react';
import ReportModal from './Report.tsx';

const ReportButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
      >
        <Flag className="w-5 h-5" />
        <span>Submit Report</span>
      </button>

      <ReportModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default ReportButton;